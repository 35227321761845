import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby-plugin-modal-routing';
import trunc from 'trunc-html';

import css from '../css/components/Project.module.css';

const Project = ({ id, title, cover, notesHtml, slug }) => {
  // const coverImage = cover_image.localFiles.childImageSharp.fluid;
  const shortDescriptionText = trunc(notesHtml, 140).text;

  return (
    <article id={`ref-${id}`} className={`raised ${css.project}`}>
      <div className={css.cover}>
        <Link to={`/projects/${slug}`} asModal>
          <Img fluid={cover} />
        </Link>
      </div>
      <Link to={`/projects/${slug}`} asModal>
        <div className={css.body}>
          <h3 className={css.title}>{title}</h3>
          <p className={css.description}>{shortDescriptionText}</p>
        </div>
      </Link>
    </article>
  );
};

export default Project;
