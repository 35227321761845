import React from 'react';
import { navigate } from "gatsby"

import css from '../css/components/Newsletter.module.css';
import buttonCss from '../css/components/Button.module.css';

export default class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.Newsletter = React.createRef()
    this.state = {}
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.Newsletter.current

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(response => {
        console.log("====================================")
        console.log(`${JSON.stringify(response, null, 2)}`)
        console.log("====================================")
        navigate(form.getAttribute("action"))
        form.reset();
      })
      .catch(error => {
        console.log("====================================")
        console.log(`error in submiting the form data:${error}`)
        console.log("====================================")
      })
  }
  render() {
    const email = 'Email';

    return (
      <form
        className={`raised ${css.form}`}
        method="POST"
        name="newsletter"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        // action="/success/"
        onSubmit={this.handleSubmit}
        ref={this.Newsletter}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <p>Get notified of the upcoming improved version of the site</p>
        <div className={css.field}>
          <input type="email" name="email" id="email" onChange={this.handleChange} placeholder="your email" required />
        </div>
        <div className={`${css.field} ${css.submit} ta-center`}>
          <button className={css.btn} type="submit">
            Get notified
          </button>
        </div>
      </form>
    );
  }
}
