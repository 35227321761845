import React from 'react';
import Project from '../components/Project';

import css from '../css/components/ProjectsGallery.module.css';

const ProjectsGallery = ({ projectsArray }) => {
  const projects = projectsArray.map(item => (
    <Project
      key={item.node.id}
      id={item.node.data.id}
      slug={item.node.fields.slug}
      title={item.node.data.title}
      authors={item.node.data.authors}
      organizations={item.node.data.organizations}
      url={item.node.data.url}
      notesHtml={item.node.data.notes.childMarkdownRemark.html}
      cover={item.node.data.cover_image.localFiles[0].childImageSharp.fluid}
    />
  ));

  return <div className={css.projects}>{projects}</div>;
};

export default ProjectsGallery;
