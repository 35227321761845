import React from 'react';
import Reference from '../components/Reference';

import css from '../css/components/ReferenceList.module.css';

const ReferenceList = ({ referencesArray }) => {
  const references = referencesArray.map(item => (
    <Reference
      key={item.node.id}
      id={item.node.data.id}
      title={item.node.data.title}
      year={item.node.data.year}
      type={item.node.data.type}
      authors={item.node.data.authors}
      organizations={item.node.data.organizations}
      url={item.node.data.url}
    />
  ));

  return <section className={css.references}>{references}</section>;
};

export default ReferenceList;
