import React from 'react';
import ReactPlayer from 'react-player';

import css from '../css/components/Video.module.css';

class Video extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { url, label } = this.props;

    return (
      <figure className={css.video}>
        <div className={`${css.videoWrapper} raised`}>
          <ReactPlayer
            url={url}
            className={css.reactPlayer}
            controls
            width="100%"
            height="100%"
            // light={true}
            config={{
              vimeo: {
                playerOptions: {
                  byline: false,
                  muted: true,
                  title: false
                }
              }
            }}
          />
        </div>
        <figcaption className={css.label}>{label}</figcaption>
      </figure>
    );
  }
}

export default Video;
