import React from 'react';
import Book from '../components/Book';

import css from '../css/components/BooksList.module.css';

const BooksList = ({ booksArray }) => {
  const books = booksArray.map(item => (
    <Book
      key={item.node.id}
      id={item.node.data.id}
      title={item.node.data.title}
      year={item.node.data.year}
      type={item.node.data.type}
      authors={item.node.data.authors}
      organizations={item.node.data.organizations}
      url={item.node.data.url}
      preview={item.node.data.preview.localFiles[0].childImageSharp.fluid}
    />
  ));

  return <section className={css.books}>{books}</section>;
};

export default BooksList;
