import React from 'react';

import css from '../css/components/Reference.module.css';

const Reference = ({ id, title, year, authors, organizations, url }) => {
  var authorList;

  if (authors) {
    const allAuthors = authors.map(person => (
      <span key={`reference-author-${person.id}`}>
        {person.data.first_name} {person.data.last_name}
      </span>
    ));
    authorList = <span className={css.authorList}>by {allAuthors}</span>;
  } else {
    const allOrganizations = organizations.map(organization => (
      <span key={`reference-author-${organization.id}`}>{organization.data.name}</span>
    ));
    authorList = <span className={css.authorList}>by {allOrganizations}</span>;
  }

  return (
    <article id={`ref-reference-${id}`} className={`raised ${css.reference}`}>
      <div className={css.body}>
        <span className={css.title}>
          <a href={url} target="_blank">
            <span className={css.name}>{title}</span> {authorList}
          </a>
        </span>
        <span className={css.year}> ({year})</span>
      </div>
    </article>
  );
};

export default Reference;
