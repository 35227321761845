import React from 'react';
import { graphql } from 'gatsby';
import { Link, animateScroll as scroll } from 'react-scroll';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectsGallery from '../components/ProjectsGallery';
import Glossary from '../components/Glossary';
import Newsletter from '../components/Newsletter';
import BooksList from '../components/BooksList';
import VideosList from '../components/VideosList';
import ReferenceList from '../components/ReferenceList';

import Logo from '../components/Logo';
import arrow from '../images/arrow-down.svg';

import css from '../css/pages/index.module.css';
import '../css/utils/modal-video.css';

class IndexPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { data } = this.props;
    const introHtml = data.intro.html;
    const glossaryArray = data.glossary.edges;
    const projectsArray = data.projects.edges.reverse();
    const booksArray = data.books.edges.reverse();
    const videosArray = data.videos.edges.reverse();
    const referencesArray = data.references.edges.reverse();

    return (
      <Layout>
        <SEO />
        <section className={`${css.intro}`}>
          <div className={`${css.introInner} raised`}>
            <h1 className={'sr-only'}>Cyberbiomes</h1>
            <div className={`${css.logo}`}>
              <Logo />
            </div>
            <div className={`${css.introText}`} dangerouslySetInnerHTML={{ __html: introHtml }} />
          </div>
          <div className={`${css.introArrow} raised`}>
            <Link href={`ref-lexicon`} to={`ref-lexicon`} smooth={true} spy={true} duration={500}>
              <img src={arrow} />
            </Link>
          </div>
        </section>

        <section id="ref-lexicon" className="wrap-l">
          <h2 className={'ta-center mb-4'}>
            <span className="decorated raised">Lexicon</span>
          </h2>
          <Glossary glossaryArray={glossaryArray} />
        </section>

        <section className="wrap-xl">
          <h2 className={`ta-center mt-6 mb-4`}>
            <span className="decorated raised">Projects</span>
          </h2>
          <ProjectsGallery projectsArray={projectsArray} />
        </section>

        <section className="wrap-xl">
          <h2 className={'ta-center mt-8 mb-4'}>
            <span className="decorated raised">Books</span>
          </h2>
          <BooksList booksArray={booksArray} />
          <h2 className={'ta-center mt-8 mb-4'}>
            <span className="decorated raised">Videos</span>
          </h2>
          <VideosList videosArray={videosArray} />
          <h2 className={'ta-center mt-8 mb-4'}>
            <span className="decorated raised">References</span>
          </h2>
          <ReferenceList referencesArray={referencesArray} />
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  {
    intro: markdownRemark(frontmatter: { title: { eq: "intro" } }) {
      html
      frontmatter {
        title
      }
    }
    glossary: allAirtable(filter: { table: { eq: "glossary" } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          data {
            term
            definition {
              childMarkdownRemark {
                html
              }
            }
            references {
              id
              data {
                id
                title
              }
            }
          }
        }
      }
    }
    books: allAirtable(filter: { table: { eq: "references" }, data: { type: { eq: "book" } } }) {
      edges {
        node {
          id
          data {
            id
            title
            type
            year
            url
            preview {
              localFiles {
                childImageSharp {
                  fluid(maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            authors {
              id
              data {
                first_name
                last_name
              }
            }
            organizations {
              id
              data {
                name
              }
            }
            keywords {
              id
              data {
                keyword
              }
            }
          }
        }
      }
    }
    videos: allAirtable(filter: { table: { eq: "references" }, data: { type: { eq: "video" } } }) {
      edges {
        node {
          id
          data {
            id
            title
            type
            year
            url
            authors {
              id
              data {
                first_name
                last_name
              }
            }
            organizations {
              id
              data {
                name
              }
            }
            keywords {
              id
              data {
                keyword
              }
            }
          }
        }
      }
    }
    references: allAirtable(
      filter: { table: { eq: "references" }, data: { type: { eq: "reference" } } }
    ) {
      edges {
        node {
          id
          data {
            id
            title
            type
            year
            url
            authors {
              id
              data {
                first_name
                last_name
              }
            }
            organizations {
              id
              data {
                name
              }
            }
            keywords {
              id
              data {
                keyword
              }
            }
          }
        }
      }
    }
    projects: allAirtable(filter: { table: { eq: "projects" } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          data {
            title
            created(formatString: "D MMMM, YYYY")
            url
            video_url
            authors {
              id
              data {
                first_name
                last_name
                url
              }
            }
            organizations {
              id
              data {
                name
                url
              }
            }
            notes {
              childMarkdownRemark {
                html
              }
            }
            cover_image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
