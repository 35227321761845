import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing';

import css from '../css/components/GlossaryDefinition.module.css';
import '../css/utils/modal.css';

const GlossaryDefinition = ({ term, slug }) => {
  return (
    <Link to={`/lexicon/${slug}`} asModal className={`raised ${css.item}`}>
      <span className={css.term}>{term}</span>
    </Link>
  );
};

export default GlossaryDefinition;
