import React from 'react';
import Video from '../components/Video';

import css from '../css/components/VideosList.module.css';

const VideosList = ({ videosArray }) => {
  const videos = videosArray.map(item => (
    <Video
      key={item.node.id}
      id={item.node.data.id}
      title={item.node.data.title}
      year={item.node.data.year}
      type={item.node.data.type}
      authors={item.node.data.authors}
      organizations={item.node.data.organizations}
      url={item.node.data.url}
    />
  ));

  return <section className={css.videos}>{videos}</section>;
};

export default VideosList;
