import React from 'react';
import GlossaryDefinition from '../components/GlossaryDefinition';

import css from '../css/components/Glossary.module.css';

const Glossary = ({ glossaryArray }) => {
  const glossary = glossaryArray.map(item => (
    <GlossaryDefinition
      key={item.node.id}
      term={item.node.data.term}
      slug={item.node.fields.slug}
      definitionHtml={item.node.data.definition.childMarkdownRemark.html}
      references={item.node.data.references}
    />
  ));

  return <div className={css.glossary}>{glossary}</div>;
};

export default Glossary;
